import request from './request'

// 获取设备列表
export const getdeviceList = data => {
  return request({
    url: 'admin/v1/deviceList',
    method: 'POST',
    data
  })
}
// 新增设备列表
export const POSTdevice = data => {
  return request({
    url: 'admin/v1/device',
    method: 'POST',
    data
  })
}

// 获取新增电子秤列表
export const deviceManu = ({ page, limit }) => {
  return request({
    url: 'admin/v1/deviceManu?limit=' + limit + '&page=' + page,
    method: 'GET'
  })
}
// 新增电子秤
export const POSTdeviceManu = data => {
  return request({
    url: 'admin/v1/deviceManu',
    method: 'POST',
    data
  })
}
// 编辑电子秤厂家
export const PUTdeviceManu = (data, id) => {
  return request({
    url: 'admin/v1/deviceManu/' + id,
    method: 'PUT',
    data
  })
}
// 获取电子秤维护列表
export const GETdeviceMaintain = ({ page, limit }) => {
  return request({
    url: 'admin/v1/deviceMaintain?page= ' + page + '&limit=' + limit,
    method: 'GET'
  })
}

// 新增电子秤维护
export const POSTdeviceMaintain = data => {
  return request({
    url: 'admin/v1/deviceMaintain',
    method: 'POST',
    data
  })
}
// 获取设备厂家列表
export const GETdeviceManu = (page = '', limit = '') => {
  return request({
    url: 'admin/v1/deviceManu?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}
// 获取设备规格列表
export const GETdeviceDec = (page = '', limit = '') => {
  return request({
    url: '/admin/v1/deviceDec?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}
// 获取商户列表
export const GETsellerList = (page = '', limit = '') => {
  return request({
    url: '/admin/v1/seller_list?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}
// 市场人员列表
export const marketManageList = (page = '', limit = '') => {
  return request({
    url: '/admin/v1/market_manage_list?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}

// 编辑电子秤厂家
export const putdeviceMaintain = (data, id) => {
  return request({
    url: `admin/v1/deviceMaintain/${id}`,
    method: 'PUT',
    data
  })
}
// 设备详情
export const getDevicedevice = device => {
  return request({
    url: `/admin/v1/device/${device}`,
    method: 'GET'
  })
}
// 交易详情
export const getorder = data => {
  return request({
    url:  `/admin/v1/orders/${data}`,
    method: 'GET'
  })
}
// 供应商

export const GetSuppliers = (page = '', limit = '') => {
  return request({
    url: '/admin/v1/suppliers?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}
