<template>
  <div>
    <el-button type="primary" @click="Addandmodify(1)">新增设备维护</el-button>
    <el-table
      :data="tableData"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      border
      style="width: 100%;margin-top:20px"
      max-height="calc(100vh - 160px-42px)"
    >
      <el-table-column prop="device_id" label="电子秤编号"> </el-table-column>
      <el-table-column prop="maintain_type" label="维护类型">
        <template slot-scope="scope">
          <span v-if="scope.row.maintain_type == 1">更换设备</span>
          <span v-else>维护</span>
        </template>
      </el-table-column>
      <el-table-column prop="maintain_describe" label="维护描述">
      </el-table-column>
      <el-table-column prop="address" label="维护照片">
        <template slot-scope="scope">
          <img
            :src="scope.row.maintain_pic"
            alt=""
            @click="$refs.img.previewImage(scope.row.maintain_pic)"
            style="width:50px;"
          />
        </template>
      </el-table-column>
      <el-table-column prop="maintain_personnel" label="维护人">
      </el-table-column>
      <el-table-column prop="created_at" label="添加时间"> </el-table-column>
      <el-table-column prop="updated_at" label="更新时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="Addandmodify(2, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="titlelable"
      @close="close"
      :visible.sync="add"
      width="33%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="电子秤编号" prop="device_id">
          <el-input
            placeholder="请填写电子秤编号"
            style="width:217px"
            v-model="ruleForm.device_id"
          ></el-input>
        </el-form-item>
        <el-form-item label="维护类型" prop="maintain_type">
          <el-select
            v-model="ruleForm.maintain_type"
            placeholder="请选择维护类型"
          >
            <el-option
              v-for="item in maintaintype"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="device_category">
          <el-select
            v-model="ruleForm.device_category"
            placeholder="请选择设备类型"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维护人" prop="maintain_personnel">
          <el-input
            placeholder="请填写维护人"
            style="width:217px"
            v-model="ruleForm.maintain_personnel"
          ></el-input>
        </el-form-item>
        <el-form-item label="维护照片" prop="maintain_pic">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            accept=".jpeg,.bmp,.png,.gif"
            :show-file-list="false"
            :http-request="uploadSectionFile"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.maintain_pic"
              :src="ruleForm.maintain_pic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="维护描述" prop="maintain_describe">
          <el-input
            placeholder="请填写维护描述"
            type="textarea"
            style="width:460px;"
            v-model="ruleForm.maintain_describe"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import {
  GETdeviceMaintain,
  POSTdeviceMaintain,
  putdeviceMaintain
} from '../../../api/electronicList'
import { upload } from '../../../utile/upload'
export default {
  name: 'electronicLists',
  data () {
    return {
      titlelable: '',
      add: false,
      total: 0,
      tableData: [],
      id: '', // 编辑按钮的id
      ids: '',
      dialogImageUrl: '', // 图片地址
      dialogVisible: false,
      hideUploadCard: false,
      ruleForm: {
        deviceMaintain: '',
        device_id: '', // 电子秤编号
        maintain_type: '', // 维护类型
        maintain_describe: '', // 维护描述 否
        maintain_pic: '', // 维护照片
        maintain_personnel: '', // 维护人
        device_category: '' // 设备类型:1=电子秤,2=商户屏,3=触摸屏,4=可视化展示屏
      },
      categoryList: [
        {
          id: 1,
          value: '电子秤'
        },
        {
          id: 2,
          value: '商户屏'
        },
        {
          id: 3,
          value: '触摸屏'
        },
        {
          id: 4,
          value: '可视化展示屏'
        }
      ],
      maintaintype: [
        { id: 1, value: '更换设备' },
        { id: 2, value: '维修' }
      ],
      rules: {
        device_id: [
          { required: true, message: '请填写电子秤编号', trigger: 'change' }
        ],
        device_category: [
          { required: true, message: '请选择设备类型', trigger: 'change' }
        ],
        maintain_type: [
          { required: true, message: '请填写维护类型', trigger: 'change' }
        ],

        maintain_pic: [
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error('上传一张维护照片'))
              } else {
                callback()
              }
            },
            trigger: 'change',
            required: true
          }
        ],
        maintain_personnel: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请填写维护人'))
              } else {
                const ref = /^[\u4e00-\u9fa5]{1,6}(·[\u4e00-\u9fa5]{1,6}){0,2}([,，][\u4e00-\u9fa5]{1,6}(·[\u4e00-\u9fa5]{1,6}){0,2})*$/
                if (ref.test(this.ruleForm.maintain_personnel)) {
                  callback()
                } else {
                  callback(new Error('请输入正确的维护人姓名'))
                }
              }
            },
            trigger: 'change',
            required: true
          }
        ],
        maintain_describe: [
          {
            required: true,
            message: '请填写维护备注信息描述',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    // 新增修改编辑弹窗
    Addandmodify (num, item) {
      console.log(item)
      this.ids = num
      this.add = true
      if (num === 1) {
        this.titlelable = '新增设备维护'
      } else {
        this.titlelable = '编辑设备维护'
        this.ruleForm.deviceMaintain = item.id
        this.ruleForm.device_id = item.device_id
        this.ruleForm.maintain_type = item.maintain_type
        this.ruleForm.maintain_pic = item.maintain_pic
        this.ruleForm.maintain_describe = item.maintain_describe
        this.ruleForm.maintain_personnel = item.maintain_personnel
        this.ruleForm.device_category = item.device_category
        // console.log(this.ruleForm)
      }
    },

    //  新增电子秤厂家
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ids === 1) {
            POSTdeviceMaintain(this.ruleForm).then(res => {
              if (res.code === 200) {
                this.$message.success(`${res.data}`)
                this.add = false
                this.ruleForm = {
                  device_id: '',
                  maintain_type: '',
                  maintain_describe: '',
                  maintain_pic: '',
                  maintain_personnel: ''
                }
                this.get_deviceManu()
              }
            })
          } else {
            putdeviceMaintain(this.ruleForm, this.ruleForm.deviceMaintain).then(
              res => {
                if (res.code === 200) {
                  this.$message.success(`${res.data}`)
                  this.add = false
                  this.get_deviceManu()
                }
              }
            )
          }
        } else {
          return false
        }
      })
    },
    close () {
      this.add = false
      this.ruleForm = {
        name: '',
        head: '',
        mobile: '',
        address: '',
        status: '启用'
      }
      this.id = ''
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的路径
    uploadSectionFile (param) {
      // this.ruleForm.maintain_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          // console.log(res)
          this.ruleForm.maintain_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 编辑按钮
    handleEdit (row) {
      this.ruleForm.name = row.name
      this.ruleForm.head = row.head
      this.ruleForm.mobile = row.mobile
      this.ruleForm.address = row.address
      this.ruleForm.status = row.status
      this.id = row.id
      this.add = true
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.get_deviceManu({ limit: val })
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.get_deviceManu({ page: val })
    },
    // 表格数据
    get_deviceManu ({ page = '', limit = '' } = {}) {
      GETdeviceMaintain({ page, limit }).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    }
  },
  mounted () {
    this.get_deviceManu()
  }
}
</script>

<style lang="less" scoped>
.footers {
  text-align: right;
  margin-top: 10px;
}

/deep/ .el-textarea__inner {
  max-height: 100px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
